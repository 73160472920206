import React, { useEffect } from 'react';
import { Header, ProjectContentDescription } from '../components';
import { Project } from '../types';

const ProjectsTemplate = ({ pageContext }: { pageContext: Project }) => {
  const { name, keywords, descriptions } = pageContext;

  useEffect(() => {
    if (window && typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'project_page_view',
        project: {
          name,
          keywords,
          descriptions,
        },
      });
    }
  }, []);

  const headerBubbles = {
    first: {
      to: '/',
      text: 'Home',
    },
    second: {
      to: '/projects',
      text: 'Projects',
    },
  };

  return (
    <>
      <Header bubbles={headerBubbles} />
      <ProjectContentDescription project={pageContext} />
    </>
  );
};

export default ProjectsTemplate;
