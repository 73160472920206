import React from 'react';
import { useLocation } from '@reach/router';
import { Redirect } from '@reach/router';
import { useProjects } from '../../hooks/useProjectData';
import ProjectsTemplate from '../../templates/projects-template';
import ConfigurationProvider from '../../providers/ConfigurationProvider';
import Helmet from '../../../Helmet';
import { Footer, Layout } from '../../components';

export default () => {
  const location = useLocation();
  const { pathname } = location;
  const slug = pathname.split('/')[2];

  const project = useProjects({ project: slug });
  if (project.length === 0) {
    return <Redirect to='/404' />;
  }

  const { name, keywords, descriptions } = project[0];

  return (
    <ConfigurationProvider>
      <Helmet
        title={name}
        shortDescription={descriptions.short}
        meta={[{ name: 'keywords', content: keywords.join(', ') }]}
      />
      <Layout>
        <ProjectsTemplate pageContext={project[0]} />
        <Footer />
      </Layout>
    </ConfigurationProvider>
  );
};
